/* eslint-disable no-param-reassign */
import { gql, useSubscription } from '@apollo/client';
import { useAuthContext } from '@authentication/Authentication';
import { useConfirmation } from '@components/modal';
import {
  Graph,
  GraphValidationError,
  useGetGraphDataMutation,
  useGetGraphLastUpdatedLazyQuery,
  useGetGraphMetadataQuery,
  useSaveGraphMutation,
  useValidateGraphMutation,
} from '@generated/UseGraphqlHooks';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ChannelNode, GraphData, Link, Node } from './types';

interface GraphDataContextType {
  graphData: GraphData;
  graphMetadata: Partial<Graph>;
  graphMetadataLoading: boolean;
  graphParseError: boolean;
  lastCreatedNodeId: string;
  links: Link[];
  nodes: Node[];
  ready: boolean;
  ready$: BehaviorSubject<boolean>;
  validationErrors: GraphValidationError[];
  createNode: (data: ChannelNode, location: [number, number]) => Promise<void>;
  createLink: (data: Pick<Link, 'from' | 'to'>) => Promise<void | { id: string }>;
  duplicateNodes: (nodeId: string[]) => void;
  refetchGraphMetadata: VoidFunction;
  removeSelectedLink: (linkId: string) => Promise<unknown>;
  removeSelectedNode: (nodeId: string, save?: boolean) => void;
  removeLink: (id: string) => Promise<void>;
  removeNode: (id: string) => void;
  saveGraph: (dataToSave: GraphData) => Promise<GraphValidationError[]>;
  updateNodeLocation: (id: string, { x, y }: { x: number; y: number }, save?: boolean) => void;
  updateNode: (id: string, newNode: ChannelNode, save: boolean) => void;
  updatePortValue: (nodeId: string, portId: string, value: unknown) => Promise<void>;
}

export const GraphDataContext = createContext({} as GraphDataContextType);

export const portValueKey = (portId: string) => `ports.${portId}.value`;

interface GraphDataProviderProps {
  workspaceId: string;
  graphId: string;
}

const graphSubscription = gql`
  subscription GraphUpdated($workspaceId: String!, $graphId: String!, $uid: String!) {
    graphUpdates(workspaceId: $workspaceId, graphId: $graphId, uid: $uid) {
      name
      description
      graphId
      graph
    }
  }
`;

export const GraphDataProvider = ({
  children,
  workspaceId,
  graphId,
}: PropsWithChildren<GraphDataProviderProps>) => {
  const { user } = useAuthContext();
  const { showConfirmation } = useConfirmation();

  const { useAsyncNotification } = useNotifications();
  const [validateGraph] = useValidateGraphMutation({ variables: { workspaceId, graphId } });
  const [getGraphLastUpdated] = useGetGraphLastUpdatedLazyQuery({
    variables: { workspaceId, graphId },
  });
  const [getGraphData] = useGetGraphDataMutation({
    variables: { workspaceId, graphId },
  });

  useSubscription(graphSubscription, {
    onData({ data }) {
      if (updateGraphUI) {
        updateGraphUI(data?.data?.graphUpdates?.graph);
      }
    },
    onError(error) {
      console.error('Apollo Subscription Error!', error);
    },
    variables: { workspaceId, graphId, uid: user.preferred_username },
    skip: !user,
  });

  useEffect(() => {
    getGraphData().then((result) => {
      updateGraphUI(result?.data?.downloadGraph);
    });
  }, [getGraphData]);

  const [saveGraphMutation] = useSaveGraphMutation();
  const {
    data: graphMetadataData,
    loading: graphMetadataLoading,
    refetch: refetchGraphMetadata,
    error: graphMetadataError,
  } = useGetGraphMetadataQuery({
    variables: { workspaceId, graphId },
  });

  const graphMetadata = graphMetadataData?.getGraphs?.[0];

  useEffect(() => {
    if (graphMetadataError) {
      void navigate(`/workspaces/${workspaceId}/graphs/`);
    }
  }, [graphMetadataError]);

  const [ready, setReady] = useState(false);
  const [graphParseError, setGraphParseError] = useState(false);
  const [graphData, setGraphData] = useState<GraphData>();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [lastNodeId, setLastNodeId] = useState(0);
  const [lastCreatedNodeId, setLastCreatedNodeId] = useState<string>();
  const [links, setLinks] = useState<Link[]>([]);
  const [ready$] = useState<BehaviorSubject<boolean>>(new BehaviorSubject(false));
  const [validationErrors, setValidationErrors] = useState<GraphValidationError[]>([]);

  const validateGraphData = async () => {
    try {
      const { data } = await validateGraph();
      const errors = data?.validateGraph || [];
      setValidationErrors(errors);
      return errors;
    } catch (e) {
      return [];
    }
  };

  const saveGraph = async (dataToSave: GraphData): Promise<GraphValidationError[]> => {
    if (!dataToSave || graphMetadata?.readOnly) {
      return Promise.resolve([] as GraphValidationError[]);
    }
    try {
      const { data } = await getGraphLastUpdated();
      const lastUpdatedAt = data?.getGraphs?.[0].updatedAt;
      if (lastUpdatedAt && lastUpdatedAt === graphMetadata?.updatedAt) {
        await saveGraphMutation({
          variables: { workspaceId, graphId, graph: JSON.stringify(dataToSave) },
        });
        return await validateGraphData();
      }
      return await Promise.resolve([] as GraphValidationError[]);
    } catch (e) {
      return Promise.resolve([] as GraphValidationError[]);
    }
  };

  const createNode = async (node: ChannelNode, [x, y]: [number, number]) => {
    const newNodeName = `${node.name}_${lastNodeId + 1}`;
    setLastNodeId((id) => id + 1);
    const newGraphNode: Node = {
      nodeClass: node.name,
      color: node.color,
      name: newNodeName,
      location: { x, y },
      ports: { inputs: node.inputs, outputs: node.outputs },
      hash: node.hash || node.schemaHash,
      links: {},
      tooltip: node.tooltip,
      values: {},
    };
    newGraphNode.ports.inputs.forEach((input) => {
      newGraphNode.values[input.name] = input.default ?? null;
    });
    setNodes((oldNodes) => [...oldNodes, newGraphNode]);
    const newGraphData = {
      ...graphData,
      nodes: {
        ...graphData.nodes,
        [newNodeName]: newGraphNode,
      },
    };
    setLastCreatedNodeId(newNodeName);
    setGraphData(newGraphData);
    await saveGraph(newGraphData);
  };

  const updateNode = (nodeName: string, newNode: ChannelNode, save = false) => {
    const newValues = {};
    newNode.inputs.forEach((input) => {
      if (Object.keys(graphData.nodes[nodeName].links).includes(input.name)) {
        return;
      }
      if (Object.keys(graphData.nodes[nodeName].values).includes(input.name)) {
        newValues[input.name] = graphData.nodes[nodeName].values[input.name];
      } else {
        newValues[input.name] = input.default ?? null;
      }
    });

    setNodes((oldNodes) => {
      const newNodes = [...oldNodes];
      const nodeToUpdate = newNodes.find((node) => node.name === nodeName);
      nodeToUpdate.nodeClass = newNode.name;
      nodeToUpdate.color = newNode.color;
      nodeToUpdate.ports = { inputs: newNode.inputs, outputs: newNode.outputs };
      nodeToUpdate.hash = newNode.hash || newNode.schemaHash;
      nodeToUpdate.tooltip = newNode.tooltip;
      nodeToUpdate.values = newValues;
      Object.keys(nodeToUpdate.links).forEach((nodeInput) => {
        if (!newNode.inputs.some((input) => input.name === nodeInput)) {
          delete nodeToUpdate.links[nodeInput];
        }
      });
      return newNodes;
    });
    setLinks((oldLinks) => {
      const newLinks = oldLinks.filter(
        (link) =>
          (link.to.nodeId !== nodeName && link.from.nodeId !== nodeName) ||
          (link.to.nodeId === nodeName &&
            !newNode.inputs.some((input) => input.name === link.to.portId)) ||
          (link.from.nodeId === nodeName &&
            !newNode.outputs.some((output) => output.name === link.from.portId)),
      );
      return newLinks;
    });
    setGraphData((oldGraphData) => {
      const newGraphData = {
        ...oldGraphData,
        nodes: {
          ...oldGraphData.nodes,
          [nodeName]: {
            ...oldGraphData.nodes[nodeName],
            nodeClass: newNode.name,
            color: newNode.color,
            ports: { inputs: newNode.inputs, outputs: newNode.outputs },
            hash: newNode.hash || newNode.schemaHash,
            tooltip: newNode.tooltip,
            values: newValues,
          },
        },
      };
      Object.keys(newGraphData.nodes[nodeName].links).forEach((nodeInput) => {
        if (!newNode.inputs.some((input) => input.name === nodeInput)) {
          delete newGraphData.nodes[nodeName].links[nodeInput];
        }
      });
      if (save) {
        void saveGraph(newGraphData);
      }
      return newGraphData;
    });
  };

  const updateNodeLocation = (
    nodeName: string,
    { x, y }: { x: number; y: number },
    save = true,
  ) => {
    setNodes((oldNodes) =>
      oldNodes.map((node) =>
        node.name === nodeName ? ({ ...node, location: { x, y } } satisfies Node) : node,
      ),
    );
    setGraphData((oldGraphData) => {
      const newGraphData = {
        ...oldGraphData,
        nodes: {
          ...oldGraphData.nodes,
          [nodeName]: {
            ...oldGraphData.nodes[nodeName],
            location: { x, y },
          },
        },
      };
      if (save) {
        void saveGraph(newGraphData);
      }
      return newGraphData;
    });
  };

  const removeNode = (nodeId: string, save = true) => {
    const newGraphData = { ...graphData };
    delete newGraphData.nodes[nodeId];

    const outgoingLinks = links.filter((link) => link.from.nodeId === nodeId);
    outgoingLinks.forEach((outgoingLink) => {
      const { nodeId: destNodeId, portId: destPortId } = outgoingLink.to;
      const destPortKey = destPortId.split('input-')[1];
      if (newGraphData.nodes[destNodeId]) {
        if (newGraphData.nodes[destNodeId].links[destPortKey].length === 1) {
          delete newGraphData.nodes[destNodeId].links[destPortKey];
        } else {
          newGraphData.nodes[destNodeId].links[destPortKey] = newGraphData.nodes[destNodeId].links[
            destPortKey
          ].filter((link) => link.sourceNode !== nodeId);
        }
      }
    });
    setGraphData(newGraphData);
    setNodes((oldNodes) => oldNodes.filter((filterNode) => filterNode.name !== nodeId));
    setLinks((oldLinks) =>
      oldLinks.filter((link) => link.to.nodeId !== nodeId && link.from.nodeId !== nodeId),
    );
    if (save) {
      void saveGraph(newGraphData);
    }
  };

  const createLink = async (link: Pick<Link, 'from' | 'to'>) => {
    const newLink = { id: uuidv4(), ...link };
    setLinks((oldLinks) => [...oldLinks, newLink]);
    const parsedToPortId = link.to.portId.split('input-')[1];
    const parsedFromPortId = link.from.portId.split('output-')[1];
    const newGraphData = {
      ...graphData,
      nodes: {
        ...graphData.nodes,
        [link.to.nodeId]: {
          ...graphData.nodes[link.to.nodeId],
          links: {
            ...graphData.nodes[link.to.nodeId].links,
            [parsedToPortId]: [
              ...(graphData.nodes[link.to.nodeId].links[parsedToPortId] || []),
              { sourceNode: link.from.nodeId, outputPort: parsedFromPortId },
            ],
          },
        },
      },
    };
    setGraphData(newGraphData);
    await saveGraph(newGraphData);
  };

  const removeLink = async (linkId: string) => {
    const {
      to: { nodeId: toNodeId, portId: toPortId },
      from: { nodeId: fromNodeId, portId: fromPortId },
    } = links.find((link) => link.id === linkId);
    const parsedPortId = toPortId.split('input-')[1];

    const newGraphData = { ...graphData };
    if (newGraphData.nodes[toNodeId]?.links[parsedPortId]?.length > 1) {
      newGraphData.nodes[toNodeId].links[parsedPortId] = newGraphData.nodes[toNodeId]?.links[
        parsedPortId
      ]?.filter(
        (link) =>
          link.sourceNode !== fromNodeId && link.outputPort !== fromPortId.split('output-')[1],
      );
    } else {
      delete newGraphData.nodes[toNodeId]?.links[toPortId.split('input-')[1]];
    }

    setGraphData(newGraphData);
    setLinks((oldLinks) => oldLinks.filter((link) => link.id !== linkId));
    await saveGraph(newGraphData);
  };

  const updateGraphUI = useCallback((result) => {
    const { nodes: parsedNodes, version: parsedVersion }: GraphData = JSON.parse(
      result || '',
    ) as GraphData;
    const mutatedNodes: { [key: string]: any }[] = [];
    const mutatedLinks: Link[] = [];
    let maxNodeId = 0;
    let save = false;
    try {
      const nodeIdNumbersToFix: string[] = [];
      Object.values(parsedNodes).forEach((parsedNode) => {
        const nodeIdNumber = parseInt(parsedNode.name.split('_').at(-1), 10);
        if (!nodeIdNumber) {
          nodeIdNumbersToFix.push(parsedNode.name);
          save = true;
        }
        maxNodeId = Math.max(maxNodeId, nodeIdNumber || 0);
        Object.entries(parsedNode.links).forEach(([destPort, sources]) => {
          sources.forEach((source) => {
            mutatedLinks.push({
              from: { nodeId: source.sourceNode, portId: `output-${source.outputPort}` },
              to: { nodeId: parsedNode.name, portId: `input-${destPort}` },
              id: uuidv4(),
            });
          });
        });
        parsedNode.ports.inputs.forEach((input) => {
          if (
            !Object.keys(parsedNode.values).includes(input.name) &&
            !Object.keys(parsedNode.links).includes(input.name)
          ) {
            // eslint-disable-next-line no-param-reassign
            parsedNode.values[input.name] = input.value ?? input.default ?? null;
            save = true;
          }
          if (
            Object.keys(parsedNode.links).includes(input.name) &&
            parsedNode.links[input.name].length > 0 &&
            Object.keys(parsedNode.values).includes(input.name)
          ) {
            // eslint-disable-next-line no-param-reassign
            delete parsedNode.values[input.name];
            save = true;
          }
        });
        mutatedNodes.push({
          color: parsedNode.color,
          configurationId: graphId,
          id: parsedNode.name,
          inputs: parsedNode.ports.inputs,
          name: parsedNode.nodeClass,
          outputs: parsedNode.ports.outputs,
          hash: parsedNode.hash,
          tooltip: parsedNode.tooltip,
          workspaceId,
          x: parsedNode.location.x,
          y: parsedNode.location.y,
          nodeClass: parsedNode.nodeClass,
          values: parsedNode.values,
        });
      });
      if (nodeIdNumbersToFix.length > 0) {
        const nodeIdNumberFixMap: Record<string, string> = {};
        nodeIdNumbersToFix.forEach((oldNodeId) => {
          const idParts = oldNodeId.split('_');
          idParts.pop();
          maxNodeId += 1;
          const newId = `${idParts.join('_')}_${maxNodeId}`;
          const newNode = parsedNodes[oldNodeId];
          newNode.name = newId;
          parsedNodes[newId] = { ...parsedNodes[oldNodeId], name: newId };
          delete parsedNodes[oldNodeId];
          nodeIdNumberFixMap[oldNodeId] = newId;
        });
        Object.values(parsedNodes).forEach((node) => {
          Object.values(node.links).forEach((nodeLinks) => {
            nodeLinks.forEach((link) => {
              if (nodeIdNumberFixMap[link.sourceNode]) {
                link.sourceNode = nodeIdNumberFixMap[link.sourceNode];
              }
            });
          });
        });
        mutatedLinks.forEach((link) => {
          if (nodeIdNumberFixMap[link.from.nodeId]) {
            link.from.nodeId = nodeIdNumberFixMap[link.from.nodeId];
          }
          if (nodeIdNumberFixMap[link.to.nodeId]) {
            link.to.nodeId = nodeIdNumberFixMap[link.to.nodeId];
          }
        });
      }
      setGraphData({ nodes: parsedNodes, version: parsedVersion });
      setNodes(Object.values(parsedNodes));
      setLinks(mutatedLinks);
      setLastNodeId(maxNodeId);
      ready$.next(true);
      setReady(true);
      if (save) {
        void saveGraph({ nodes: parsedNodes, version: parsedVersion });
      } else {
        void validateGraphData();
      }
    } catch (e) {
      console.error('Error parsing graph data', e);
      setGraphParseError(true);
    }
  }, []);

  const onCreateNode = useAsyncNotification(
    'Created new node',
    async (node: ChannelNode, location: [number, number]) => {
      if (process.env.JEST_WORKER_ID === undefined) {
        await createNode(node, location);
      }
    },
  );

  const removeSelectedNode = (nodeId: string, save = true) => {
    removeNode(nodeId, save);
  };

  const updatePortValue = async (nodeId: string, portId: string, value: unknown) => {
    if (!nodeId) {
      return;
    }
    const parsedPortId = portId.split('-', 2)[1];
    const newGraphData = { ...graphData };
    newGraphData.nodes[nodeId].values[parsedPortId] = value?.toString();
    setGraphData(newGraphData);
    await saveGraph(newGraphData);
  };

  const removeSelectedLink = (linkId: string) =>
    showConfirmation({
      onAffirm: useAsyncNotification('Removed link.', async () => {
        await removeLink(linkId);
        await validateGraphData();
      }),
      message: 'Are you sure you want to delete selected link(s)?',
    });

  const duplicateNodes = (nodeList: string[]) => {
    let newLastNodeId = lastNodeId;
    const newNodes = [...nodes];
    const newGraphData = { ...graphData };
    const newLinks = [...links];
    nodeList.forEach((nodeId) => {
      const node = nodes.find((searchNode) => searchNode.name === nodeId);
      if (!node) {
        return;
      }
      const nodeName = node.name.match(/(.+)_\d+$/)?.[1] || node.name.split('_')[0];
      newLastNodeId += 1;
      const newNodeName = `${nodeName}_${newLastNodeId}`;
      const newLocation = {
        x: node.location.x + 20,
        y: node.location.y + 20,
      };
      newNodes.push({
        ...node,
        ...newLocation,
        name: newNodeName,
      });
      newGraphData.nodes[newNodeName] = {
        ...graphData.nodes[node.name],
        name: newNodeName,
        location: newLocation,
        links: {},
      } as Node;
      const linksToDupe = links.filter(
        (link) => link.to.nodeId === node.name || link.from.nodeId === node.name,
      );
      linksToDupe.forEach((linkToDupe) => {
        if (linkToDupe.from.nodeId === node.name) {
          const newLink = {
            ...linkToDupe,
            from: { ...linkToDupe.from, nodeId: newNodeName },
            id: uuidv4(),
          };
          newLinks.push(newLink);
          newGraphData.nodes[newLink.to.nodeId].links[newLink.to.portId.split('input-')[1]].push({
            sourceNode: newNodeName,
            outputPort: newLink.from.portId.split('output-')[1],
          });
        } else {
          const newLink = {
            ...linkToDupe,
            to: { ...linkToDupe.to, nodeId: newNodeName },
            id: uuidv4(),
          };
          newLinks.push(newLink);
          if (
            !Object.keys(newGraphData.nodes[newNodeName].links).includes(
              newLink.to.portId.split('input-')[1],
            )
          ) {
            newGraphData.nodes[newNodeName].links[newLink.to.portId.split('input-')[1]] = [];
          }
          newGraphData.nodes[newNodeName].links[newLink.to.portId.split('input-')[1]].push({
            sourceNode: newLink.from.nodeId,
            outputPort: newLink.from.portId.split('output-')[1],
          });
        }
      });
    });

    setLastNodeId(newLastNodeId);
    setNodes(newNodes);
    setLinks(newLinks);
    setGraphData(newGraphData);
    void saveGraph(newGraphData);
  };

  const providerValue = useMemo(
    () => ({
      graphData,
      graphMetadata,
      graphMetadataLoading,
      graphParseError,
      lastCreatedNodeId,
      links,
      nodes,
      ready,
      ready$,
      validationErrors,
      createNode: onCreateNode,
      createLink,
      duplicateNodes,
      refetchGraphMetadata,
      removeLink,
      removeNode,
      removeSelectedLink,
      removeSelectedNode,
      saveGraph,
      updateNode,
      updateNodeLocation,
      updatePortValue,
      validateGraphData,
    }),
    [
      graphData,
      graphMetadata,
      graphMetadataLoading,
      graphParseError,
      lastCreatedNodeId,
      links,
      nodes,
      ready,
      ready$,
      validationErrors,
      createLink,
      duplicateNodes,
      onCreateNode,
      refetchGraphMetadata,
      removeLink,
      removeNode,
      removeSelectedLink,
      removeSelectedNode,
      saveGraph,
      updateNode,
      updateNodeLocation,
      updatePortValue,
      validateGraphData,
    ],
  );

  return <GraphDataContext.Provider value={providerValue}>{children}</GraphDataContext.Provider>;
};
