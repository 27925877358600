import seedRandom from 'seedrandom';
import { ImageAnnotations } from './DatasetsDetailsImagesSliderTypes';

interface DatasetsDetailsImagesSliderSegmentationOverlayProps {
  data: ImageAnnotations[];
  strokeWidth: number;
  opacity: number;
  fill: boolean;
}

export const DatasetsDetailsImagesSliderSegmentationOverlay = ({
  data,
  strokeWidth,
  opacity,
  fill,
}: DatasetsDetailsImagesSliderSegmentationOverlayProps) => {
  const seededRandom = seedRandom(JSON.stringify(data));
  return (
    <>
      {data?.map(({ segmentation }, objIdx) => {
        const color = `${seededRandom.quick() * 255}, ${seededRandom.quick() * 255}, ${
          seededRandom.quick() * 255
        }`;
        return segmentation.map((seg, segIdx) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <g key={`${objIdx}-${segIdx}`}>
              <polygon
                points={seg
                  .map((e, i) => {
                    if (i % 2 === 0 && i < seg.length - 1) {
                      return `${e},${seg[i + 1]}`;
                    }
                    return '';
                  })
                  .join(' ')}
                strokeWidth={strokeWidth}
                stroke={`rgb(${color})`}
                fill={fill ? `rgba(${color},${opacity})` : 'none'}
              />
            </g>
          );
        });
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};
