import { Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { useCreateGraphMutation, useGetChannelsQuery } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import { useState } from 'react';
import * as yup from 'yup';
import { GraphsAddGraphModalAvailableChannels } from './GraphsAddGraphModalAvailableChannels';
import { WorkspaceGraphsAddGraphModalUploadFile } from './GraphsAddGraphModalUploadFile';

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters.')
    .required('Name is required'),
  channelId: yup.string().required('Required'),
});

interface GraphsAddGraphModalProps {
  workspaceId: string;
  onClose: () => void;
}

export const GraphsAddGraphModal = ({ onClose, workspaceId }: GraphsAddGraphModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [createGraph] = useCreateGraphMutation();
  const { data, loading: channelsLoading } = useGetChannelsQuery({
    variables: { workspaceId },
  });
  const { getChannels } = data || {};
  const [graphData, setGraphData] = useState<string>();
  const handleCreateGraphClick = useAsyncNotification(
    'Created a new graph & now navigating...',
    async ({
      name,
      description,
      channelId,
    }: {
      name: string;
      description: string;
      channelId: string;
    }) => {
      const { data: createGraphData } = await createGraph({
        variables: {
          name,
          description,
          channelId,
          graph: graphData || '',
          staged: false,
          workspaceId,
        },
      });
      onClose();
      await navigate(`/workspaces/${workspaceId}/graphs/${createGraphData?.createGraph || ''}/`);
    },
  );

  return (
    <Form
      validateOnBlur={false}
      onSubmit={handleCreateGraphClick}
      initialValues={{ name: '', description: '', channelId: '' }}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty }) => (
        <Stack>
          <FormTextInput label="Name" name="name" />
          <GraphsAddGraphModalAvailableChannels
            availableChannels={channelsLoading ? [] : getChannels}
            name="channelId"
          />
          <WorkspaceGraphsAddGraphModalUploadFile
            onChange={(newGraphData) => setGraphData(newGraphData)}
          />
          <FormTextInput
            name="description"
            label="Description"
            multiline
            rows={6}
            inputProps={{ maxLength: 200 }}
          />
          <Stack gap={4}>
            <Button
              fullWidth
              type="submit"
              data-cy="Graphs-Add-Graph-Modal-Create-Button"
              disabled={!(isValid && dirty)}
            >
              Create
            </Button>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Graphs-Add-Graph-Modal-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
