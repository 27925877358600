import { Fragment } from 'react';
import seedRandom from 'seedrandom';
import { ImageAnnotations } from './DatasetsDetailsImagesSliderTypes';

interface DatasetsDetailsImagesSlider3DBoxesOverlayProps {
  data: ImageAnnotations[];
  strokeWidth: number;
}

export const DatasetsDetailsImagesSlider3DBoxesOverlay = ({
  data,
  strokeWidth,
}: DatasetsDetailsImagesSlider3DBoxesOverlayProps) => {
  const seededRandom = seedRandom(JSON.stringify(data));
  return (
    <>
      {data?.map(({ id, bbox3d }, index) => {
        const stroke = `rgb(${seededRandom.quick() * 255}, ${seededRandom.quick() * 255}, ${
          seededRandom.quick() * 255
        })`;
        return (
          <Fragment key={id || index}>
            <path
              d={`M${bbox3d[0]} ${bbox3d[1]} L${bbox3d[3]} ${bbox3d[4]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[3]} ${bbox3d[4]} L${bbox3d[6]} ${bbox3d[7]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[6]} ${bbox3d[7]} L${bbox3d[9]} ${bbox3d[10]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[9]} ${bbox3d[10]} L${bbox3d[0]} ${bbox3d[1]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />

            <path
              d={`M${bbox3d[12]} ${bbox3d[13]} L${bbox3d[15]} ${bbox3d[16]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[15]} ${bbox3d[16]} L${bbox3d[18]} ${bbox3d[19]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[18]} ${bbox3d[19]} L${bbox3d[21]} ${bbox3d[22]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[21]} ${bbox3d[22]} L${bbox3d[12]} ${bbox3d[13]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />

            <path
              d={`M${bbox3d[0]} ${bbox3d[1]} L${bbox3d[12]} ${bbox3d[13]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[3]} ${bbox3d[4]} L${bbox3d[15]} ${bbox3d[16]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[6]} ${bbox3d[7]} L${bbox3d[18]} ${bbox3d[19]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[9]} ${bbox3d[10]} L${bbox3d[21]} ${bbox3d[22]} Z`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
          </Fragment>
        );
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};
