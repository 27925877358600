import { Banner } from '@components/banner';
import { Button } from '@components/button';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { useGetPreviewQuery } from '@generated/UseGraphqlHooks';
import { useTheme } from '@mui/material';
import { useCurrentWorkspace } from '@navigation/WorkspaceContext';
import { useNotifications } from '@notifications/Notifications';
import { useContext, useEffect, useState } from 'react';
import { GraphsListViewPreviewMenuDuplicateConfiguration } from '../GraphsListViewPreviewMenuDuplicateConfiguration';
import { GraphConfigStageButton } from './GraphConfigStageButton';
import { GraphDataContext } from './GraphDataProvider';
import { GraphHeaderDropdown } from './GraphHeaderDropdown';
import { GraphPreviewModal } from './GraphPreviewModal';

export const GraphHeader = () => {
  const { palette } = useTheme();
  const {
    workspace: { workspaceId },
  } = useCurrentWorkspace();
  const { graphMetadata, graphParseError, refetchGraphMetadata } = useContext(GraphDataContext);
  const disabled = graphMetadata?.readOnly;
  const { addNotification, removeNotification } = useNotifications();

  const [previewId, setPreviewId] = useState<string>();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const { showModal: showDuplicateModal } = useModal({
    component: GraphsListViewPreviewMenuDuplicateConfiguration,
    componentProps: { ...graphMetadata, refetch: refetchGraphMetadata },
    modalProps: { title: `Save ${graphMetadata?.name || 'graph'} copy as` },
  });

  const preview = useGetPreviewQuery({
    variables: { previewId, workspaceId },
    pollInterval: 3000,
  });

  useEffect(() => {
    preview.stopPolling();
    if (!previewId) {
      return;
    }
    preview
      .refetch({ previewId })
      .then(() => {
        preview.startPolling(3000);
      })
      .catch((e) => console.error(e));
  }, [previewId]);

  const previewStatus = preview?.data?.getPreview?.status;

  useEffect(() => {
    removeNotification('PREVIEW_STATUS');
    if (!previewStatus) {
      return;
    }
    if (['succeeded', 'success', 'complete'].includes(previewStatus)) {
      addNotification({
        key: 'PREVIEW_STATUS',
        type: 'success',
        text: 'Preview generation complete',
        duration: 3000,
      });
    } else if (['failed', 'timeout'].includes(previewStatus)) {
      addNotification({
        key: 'PREVIEW_STATUS',
        type: 'error',
        text: 'Preview generation failed',
        duration: 5000,
      });
    } else {
      addNotification({
        key: 'PREVIEW_STATUS',
        type: 'loading',
        text: `Generating preview: ${previewStatus}`,
        duration: 600000,
      });
    }
  }, [preview?.data?.getPreview?.status]);

  useEffect(() => {
    return () => {
      removeNotification('PREVIEW_STATUS');
    };
  }, []);

  if (['failed', 'success', 'timeout'].includes(previewStatus)) {
    preview?.stopPolling();
  }

  return (
    <Banner>
      <Group justifyContent="space-between" alignItems="center" height="100%" position="relative">
        <Group
          position="absolute"
          width="100%"
          height="100%"
          justifyContent="center"
          display="flex"
          alignItems="center"
        >
          {disabled && (
            <Typography variant="subtitle1" color={palette.common.white}>
              Readonly
            </Typography>
          )}
        </Group>
        <Group alignItems="center" justifyContent="flex-start" gap={3}>
          <Typography
            variant="subtitle1"
            color={palette.common.white}
            handleOverFlow
            sx={{ width: 'auto', maxWidth: '500px' }}
          >
            {graphMetadata?.name || ''}
          </Typography>
          <GraphHeaderDropdown />
        </Group>
        {!graphParseError && (
          <Group spacing={4} alignItems="center">
            {disabled ? (
              <Button variant="secondary" size="small" onClick={showDuplicateModal}>
                Save as and edit
              </Button>
            ) : (
              <>
                <Button
                  data-cy="Graph-Config-Preview-Button"
                  variant="secondary"
                  size="small"
                  onClick={() => setOpenPreviewModal(true)}
                >
                  Preview
                </Button>
                <GraphConfigStageButton />
              </>
            )}
          </Group>
        )}
      </Group>
      <GraphPreviewModal
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        graphId={graphMetadata?.graphId}
        graphName={graphMetadata?.name}
        previewId={previewId}
        setPreviewId={setPreviewId}
        preview={preview?.data?.getPreview}
      />
    </Banner>
  );
};
